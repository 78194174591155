<template>
  <div>
    <div class="length-text">
      <span>Показано {{ list.length }} {{ getSpell(list.length) }} из {{ total }}</span>
    </div>
    <el-table
      ref="table"
      v-loading="loading"
      :data="list"
      border
      fit
      size="mini"
      class="main-table"
      :header-cell-class-name="headerClass"
      :row-class-name="tableRowClassName"
      @header-click="handleHeaderClick"
      @expand-change="expandChange"
      height="80vh">
      <el-table-column
        type="expand"
        :sort-orders="[]">
        <template slot="header">
          <div
            v-if="showTable"
            class="el-table__expand-icon"
            v-bind:class="{'el-table__expand-icon--expanded': expanded}"
            @click="expandRows">
            <i class="el-icon el-icon-arrow-right"/>
          </div>
        </template>
        <template slot-scope="props">
          <storage-detail
            v-if="rerenderStorageRow && props.row.classification_id !== 1 && props.row.classification_id !== 4"
            :id="props.row.id" @updated="(val) => updateRow(val, props.row)"></storage-detail>
        </template>
      </el-table-column>
      <el-table-column
        v-if="needId"
        label="#"
        align="center"
        :prop="keyField"
        label-class-name="order">
        <el-table-column
          align="center"
          :prop="keyField"
          :sort-orders="[]"
          width="80px">
          <template slot="header">
            <v-input
              v-model="query[keyField]"
              @change="fetchData(true)"/>
          </template>
          <router-link
            slot-scope="scope"
            :to="detailRoute(scope.row)"
            class="link-type">
            {{ scope.row[keyField] }}
          </router-link>
        </el-table-column>
      </el-table-column>
      <el-table-column
        v-for="column in columns"
        :key="column.field"
        :label="column.label"
        :prop="column.field"
        :align="column.align || 'center'"
        label-class-name="order">
        <el-table-column
          v-if="showTable"
          :align="column.align || 'left'"
          :prop="column.field"
          :width="column.width"
          :sort-orders="[]">
          <template slot="header"
                    v-if="column.filterable !== false">
            <el-select
              v-if="column.items"
              v-model="query[column.field]"
              size="mini"
              clearable
              style="width: 100%"
              :multiple="column.multiple"
              @change="fetchData(true)">
              <el-option
                v-for="(item) in column.items"
                :key="item.id"
                :label="item.title"
                :value="item.id"/>
            </el-select>
            <v-input
              v-else-if="renderFilters"
              v-model="query[column.field]"
              @change="fetchData(true)"/>
          </template>
          <template slot-scope="scope">
            <el-checkbox
              v-if="column.type === 'checkbox'"
              v-model="scope.row[column.field]"
              @change="value => $emit(`${column.field}-checked`, scope.row, value)"/>
            <el-tag
              v-else-if="column.type === 'tag'"
              :type="column.resolveType(scope.row)">
              {{ getValue(scope.row, column) }}
            </el-tag>
            <el-switch
              v-else-if="column.type === 'params' && scope.row.classification_id !== 1 && scope.row.classification_id !== 4"
              v-model="scope.row.data_exist"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="(val) => changeParams(val, scope.row)">
            </el-switch>
            <katex-element v-if="column.type === 'formula' && scope.row[column.field].indexOf('`') >= 0"
                           :expression="getFormula(scope.row[column.field])"/>
            <template v-else>
              {{ getValue(scope.row, column) }}
            </template>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        v-if="needActions"
        label="Действия"
        align="center"
        prop="actions"
        :sort-orders="[]">
        <el-table-column
          align="center"
          prop="actions"
          :sort-orders="[]"
          width="100px">
          <template slot="header">
          </template>
          <template slot-scope="scope">
            <a title="Просмотр" @click="$emit('storage-view', scope.row.id)"
               v-if="scope.row.classification_id !== 1 && scope.row.classification_id !== 4">
              <i class="fas fa-eye" style="margin-right: 10px"/>
            </a>
            <a title="Редактирование" @click="$emit('storage-edit', scope.row)">
              <i class="fas fa-edit" style="margin-right: 10px"/>
            </a>
            <el-upload
              style="display: inline"
              :v-loading="loading"
              ref="file"
              action="/backend/file/upload"
              :headers="{Authorization: 'Bearer ' + token}"
              :show-file-list="false"
              :on-success="(val) => addFile(val, scope.row)">
              <i title="Добавить файл" class="fas fa-file-upload" slot="trigger"/>
            </el-upload>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :auto-scroll="autoScroll"
      :limit.sync="query.per_page"
      :page.sync="query.current_page"
      :total="total"
      @pagination="fetchData()"/>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import VInput from '@/components/VInput'
import StorageDetail from '@/views/storage/StorageDetail'
import AsciiMathParser from 'asciimath2tex'
import { saveDataExist, saveStorageAddFile, saveStorageKpAddFile } from '@/api/api'
import { mapGetters } from 'vuex'

export default {
  components: { Pagination, VInput, StorageDetail },
  props: {
    keyField: {
      type: String,
      default: 'id'
    },
    name: {
      type: String,
      required: true
    },
    columns: {
      type: Array,
      default: () => []
    },
    list: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    },
    needActions: {
      type: Boolean,
      default: false
    },
    rowClassName: {
      type: Function,
      default: () => {
        return ''
      }
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    needId: {
      type: Boolean,
      default: true
    },
    renderFilters: {
      type: Boolean,
      default: true
    },
    detailRoute: {
      type: Function,
      default (params) {
        return { name: this.$route.name.replace('list', 'view'), params }
      }
    }
  },
  data () {
    return {
      loading: false,
      expanded: false,
      isKP: false,
      rerenderStorageRow: true,
      query: {
        total: 0,
        filter: undefined,
        current_page: 1,
        per_page: 20,
        sort_column: undefined,
        sort_direction: undefined
      },
      filters: [],
      labels: {},
      showTable: true,
      showFilters: false
    }
  },
  computed: {
    ...mapGetters(['token'])
  },
  watch: {
    columns: {
      handler () {
        this.showTable = false
        this.$nextTick(() => {
          this.showTable = true
        })
      }
    }
  },
  mounted () {
    this.fetchData()
    if (this.$route.meta?.isKP) {
      this.isKP = true
    }
  },
  methods: {
    getSpell (val) {
      if (val % 100 < 20 && val % 100 > 10) {
        return 'записей'
      }
      if (val % 10 === 1) {
        return 'запись'
      } else if (val % 10 < 5 && val % 10 > 1) {
        return 'записи'
      }
      return 'записей'
    },
    tableRowClassName ({ row }) {
      if (row.classification_id !== 1 && row.classification_id !== 4) {
        if (row.result || !row.data_exist) {
          return 'row-success'
        }
      } else {
        const values = row.result.split('/')
        if (values.length === 2) {
          return values[0] === values[1] ? 'row-success' : 0
        }
      }
      return 0
    },
    expandChange (row) {
      if (row.classification_id === 1) {
        this.$router.push({ name: '/storage/overall', query: { pki_id: row.pki_id, period_id: row.period_id } })
      } else if (row.classification_id === 4) {
        this.$router.push({ name: '/kp-storage/overall', query: { pki_id: row.pki_id, period_id: row.period_id } })
      }
    },
    addFile (fileId, row) {
      const form = {
        file_by_operator_id: fileId
      }
      if (row.id) {
        form.storage_id = row.id
      } else {
        form.pki_id = row.pki_id
        form.period_id = row.period_id
      }
      let promise
      if (this.isKP) {
        promise = saveStorageAddFile(form)
      } else {
        promise = saveStorageKpAddFile(form)
      }
      promise.then(() => {
        this.$message.success('Файл прикреплен!')
        this.rerenderStorage()
      }).catch(() => {
        this.$message.error('Файл не удалось прикрепить!')
      })
    },
    changeParams (val, row) {
      saveDataExist({ storage_id: row.id, data_exist: val }).then((response) => {
        this.rerenderStorage()
        row = Object.assign(row, response.data.storageModel)
        this.$emit('change-graph')
      }).catch(() => {
        row.data_exist = !val
      })
    },
    expandRows () {
      if (this.expanded) {
        this.expanded = false
        for (let i = 0; i < this.query.per_page; i++) {
          if (this.list[i].classification_id !== 1 && this.list[i].classification_id !== 4) {
            this.$refs.table.toggleRowExpansion(this.list[i], false)
          }
        }
      } else {
        this.expanded = true
        for (let i = 0; i < this.query.per_page; i++) {
          if (this.list[i].classification_id !== 1 && this.list[i].classification_id !== 4) {
            this.$refs.table.toggleRowExpansion(this.list[i], true)
          }
        }
      }
      this.rerender()
    },
    rerender () {
      this.showTable = false
      this.$nextTick(() => {
        this.showTable = true
      })
    },
    rerenderStorage () {
      this.rerenderStorageRow = false
      this.$nextTick(() => {
        this.rerenderStorageRow = true
      })
    },
    updateRow (data, row) {
      row.result = data.storageModel.result
      row.rating = data.storageModel.rating
      row.data_exist = data.storageModel.data_exist
    },
    getFormula (val) {
      if (val.indexOf('`') >= 0) {
        val = val.replaceAll('`', '')
        const parser = new AsciiMathParser()
        return parser.parse(val).replaceAll('∑', 'Σ')
      }
      return val
    },
    getValue (row, column) {
      if (column.filter) {
        return column.filter(row[column.field], row)
      }
      return row[column.field]
    },
    handleDblClick (row) {
      this.$router.push(this.detailRoute(row))
    },
    fetchData (toFirstPage = false) {
      if (toFirstPage) {
        this.query.current_page = 1
      }
      this.query.filter = this.renderFilters
      this.$emit('fetch-data', this.query)
      this.rerender()
    },
    handleHeaderClick (col) {
      if (col.sortOrders.length) {
        if (col.property === this.query.sort_column) {
          if (this.query.sort_direction === 'asc') {
            this.query.sort_direction = 'desc'
          } else {
            this.query.sort_column = undefined
            this.query.sort_direction = undefined
          }
        } else {
          this.query.sort_column = col.property
          this.query.sort_direction = 'asc'
        }
        this.fetchData()
      }
    },
    headerClass (data) {
      if (data.column.property && data.column.property === this.query.sort_column) {
        return this.query.sort_direction
      }
    }
  }
}
</script>

<style scoped>

.main-table >>> .el-table__header-wrapper {
  /* need for sticky header */
  top: 50px;
  z-index: 1;
}

.el-table >>> .cell {
  line-height: 17px;
}

.el-table >>> .el-input__suffix {
  right: 10px;
}

.el-table >>> thead > tr:nth-child(2) > th > div.cell {
  line-height: 0;
  padding: 0 10px;
}

.buttons > * + * {
  margin-left: 10px;
}

.el-table >>> .el-table__body-wrapper {
  overflow-y: auto;
}
</style>
